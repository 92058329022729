/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './reset.scss'
import { API_URL } from '../ApiURL';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


const ResetPin = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('token');
    const [mainLoader, setMainLoader] = useState(false);
    const [newPinSet, setNewPinSet] = useState(null);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .required('email pin is required')
    });

    // console.log("id",id)
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmitHandle = () => {
        console.log('start');
        setMainLoader(true)
        axios.post(`${API_URL}/api1/user/forget/Pin`, { newPin: newPinSet }, { headers: { "Authorization": `Bearer ${id}`, "Content-Type": "application/json" } })
            .then((res) => {
                console.log("🚀 ~ .then ~ res:", res)
                setMainLoader(false)
                // alert(res.msg)
                toast(res?.data?.msg);
                return res;
            }).catch((err) => {
                console.log("🚀 ~ .then ~ err:", err)
                setMainLoader(false)
                // alert(err.msg)
                toast.error(err);
                return err;
            })
    }
    return (
        <>
            {/* <div className='text-center mt-5 mb-5'>

                <img src='legion-logo (2).svg' alt='' width='auto' className='img-fluid' />
            </div> */}
            <section className="main-reffrel1">
                <div className='mainlogo'>
                    <img src='\legion-black-logo.svg' className='logoimg' alt='img' />
                </div>
                <div className='main_box col-sm-11 col-lg-4'>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="cmn-tile-style">
                                <h3 className='reset_heading'>Reset your pin</h3>
                                <p className='reset_para'>Please enter your password and the pin you received. </p>
                                {/* <form> */}
                                <div className="form-group">
                                    <label>Enter your new pin</label>
                                    <input type="password" name="password" onChange={(e) => setNewPinSet(e?.target?.value)}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        placeholder="Enter your pin" />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>

                                {/* <div className="form-group">
                                        <label>Enter email code</label>
                                        <input name="confirmPassword" type="password"
                                            {...register('confirmPassword')}
                                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            placeholder="Enter email code" />
                                        <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                    </div> */}
                                <button type="submit" className={!mainLoader ? "btn-common" : 'btn-common1'} onClick={() => onSubmitHandle()}>Submit</button>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPin;
