import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ResetPassword from './components/ResetPassword';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ResetPin from './components/ResetPin';

function App() {
  return (
    <>
          <Router>
          <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
            <Switch>
              <Route exact path='/' component={ResetPassword} />
              <Route exact path='/resetpin' component={ResetPin} />
              {/* <Route exact path='/verify' component={LoginApp} /> */}
            {/* <LoginApp/> */}
              {/* <Route exact path='/resetpassword'element={<ResetPassword />} /> */}
              {/* <Route exact path='/resetpassword' component={ResetPassword} /> */}
            </Switch>
          </Router>
    </>
  );
}

export default App;
